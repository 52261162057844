<template>
  <div
    class="
      mr-8
      ml-6
      mt-5
      mb-5
      md:mr-20 md:ml-14 md:mt-12 md:mb-12
      lg:mr-36 lg:ml-32 lg:mt-20 lg:mb-20
    "
  >
    <div class="lg:mx-30">
      <div>
        <h1 class="text-4xl font-medium text-center">
          Information sur l'API d'Econect
        </h1>
        <p class="mt-8 ml-2 mr-2 text-justify">
          Le projet Econect propose une API de récupération des données
          disponible à l'adresse
          <a href="https://econect.univ-tlse3.fr/arnaud/documentation"
            >https://econect.univ-tlse3.fr/arnaud</a
          >
        </p>
      </div>

      <div>
        <h2 class="text-2xl font-medium mt-6">Exemple d'utilisation :</h2>

        <!--
        <h3 class="ml-5 mt-4 font-medium">Création de compte :</h3>
        <p class="mt-5 ml-2 mr-2 text-justify"></p>

        <div
          class="
            bg-blue-400 bg-opacity-30
            border
            blue_
            rounded
            pb-1
            pl-1
            pt-0.5
            mt-7
            shadow-lg
          "
        >
          <p>
            <span
              class="blue rounded-sm text-white pl-4 pr-4 pt-0.5 pb-0.5 text-sm"
              >GET</span
            >
            <span class="text-xs"> <strong>user</strong></span>
          </p>
        </div>

        <div
          class="
            bg-green-400 bg-opacity-30
            border
            green_
            rounded
            pb-1
            pl-1
            pt-0.5
            mt-7
            shadow-lg
          "
        >
          <p>
            <span
              class="
                green
                rounded-sm
                text-white
                pl-4
                pr-4
                pt-0.5
                pb-0.5
                text-sm
              "
              >POST</span
            >
            <span class="text-xs"> <strong> /user/</strong></span>
            <span class="text-xs">Creates a new User</span>
          </p>
        </div>

        <div>
          <p class="mb-1 ml-3 mt-7">
            La requête doit contenir la payload suivante :
          </p>

          <div class="border-black max-w-max pb-1 pl-1 pt-0.5 ml-6">
            <p class="p-2 text-white bg-black border-black rounded">
              <br />
              "email": "string"<br />
              "username": "tring"<br />
              "password": "string"<br />
            </p>
          </div>
        </div>

        <div class="mt-10">
          <h3 class="font-medium">Login/Logout</h3>

          <div
            class="
              bg-green-400 bg-opacity-30
              border
              green_
              rounded
              pb-1
              pl-1
              pt-0.5
              mt-5
              shadow-lg
            "
          >
            <p>
              <span
                class="
                  green
                  rounded-sm
                  text-white
                  pl-4
                  pr-4
                  pt-0.5
                  pb-0.5
                  text-sm
                "
                >POST</span
              >
              <span class="text-xs"> /auth/login</span>
            </p>
          </div>

          <div
            class="
              bg-green-400 bg-opacity-30
              border
              green_
              rounded
              pb-1
              pl-1
              pt-0.5
              mt-4
              shadow-lg
            "
          >
            <p>
              <span
                class="
                  green
                  rounded-sm
                  text-white
                  pl-4
                  pr-4
                  pt-0.5
                  pb-0.5
                  text-sm
                "
                >POST</span
              >
              <span class="text-xs"> /auth/logout</span>
            </p>
          </div>
        </div>

        -->

        <div class="mt-10">
          <h2 class="font-medium">Récuperer des données</h2>

          <div>
            <div>
              <div class="flex items-center mt-4">
                <img
                  class="object-contain h-30 w-10"
                  src="@/assets/img/sea.svg"
                />
                <h3 class="ml-5">Aquacosme</h3>
              </div>

              <div
                class="
                  bg-blue-400 bg-opacity-30
                  border
                  blue_
                  rounded
                  pb-1
                  pl-1
                  pt-0.5
                  mt-4
                  shadow-lg
                  break-all
                "
              >
                <p>
                  <span
                    class="
                      blue
                      rounded-sm
                      text-white
                      pl-4
                      pr-4
                      pt-0.5
                      pb-0.5
                      text-sm
                    "
                    >GET</span
                  >
                  <span class="text-xs">
                    <strong> /aquacosmes/measurements/site/{site_id}/</strong> liste des paramètres disponibles pour la sentinelle aquacosme et pour un site donné</span
                  >
                </p>
              </div>

              <div
                class="
                  bg-blue-400 bg-opacity-30
                  border
                  blue_
                  rounded
                  pb-1
                  pl-1
                  pt-0.5
                  mt-4
                  shadow-lg
                  break-all
                "
              >
                <p>
                  <span
                    class="
                      blue
                      rounded-sm
                      text-white
                      pl-4
                      pr-4
                      pt-0.5
                      pb-0.5
                      text-sm
                    "
                    >GET</span
                  >
                  <span class="text-xs">
                    <strong>
                      /aquacosmes/site/{site_id}/parameter/{parameter_id}</strong
                    >
                    récupération des données de la sentinelle aquacosme pour un paramètre et un site donné</span
                  >
                </p>
              </div>
            </div>

            <div>
              <div class="flex items-center mt-10">
                <img
                  class="object-contain h-30 w-10"
                  src="@/assets/img/bee.svg"
                />
                <h3 class="ml-5">Ruche connectée</h3>
              </div>

              <div
                class="
                  bg-blue-400 bg-opacity-30
                  border
                  blue_
                  rounded
                  pb-1
                  pl-1
                  pt-0.5
                  mt-4
                  shadow-lg
                  break-all
                "
              >
                <p>
                  <span
                    class="
                      blue
                      rounded-sm
                      text-white
                      pl-4
                      pr-4
                      pt-0.5
                      pb-0.5
                      text-sm
                    "
                    >GET</span
                  >
                  <span class="text-xs">
                    <strong> beehives/measurements/site/{site_id}</strong> liste des paramètres disponibles pour la sentinelle ruche et pour un site donné</span
                  >
                </p>
              </div>

              <div
                class="
                  bg-blue-400 bg-opacity-30
                  border
                  blue_
                  rounded
                  pb-1
                  pl-1
                  pt-0.5
                  mt-4
                  shadow-lg
                  break-all
                "
              >
                <p>
                  <span
                    class="
                      blue
                      rounded-sm
                      text-white
                      pl-4
                      pr-4
                      pt-0.5
                      pb-0.5
                      text-sm
                    "
                    >GET</span
                  >
                  <span class="text-xs">
                    <strong>
                      /beehive/site/{site_id}/parameter/{parameter_id}</strong
                    >
                    récupération des données de la sentinelle ruche pour un paramètre et un site donné</span
                  >
                </p>
              </div>
            </div>

            <div>
              <div class="flex items-center mt-10">
                <img
                  class="object-contain h-30 w-10"
                  src="@/assets/img/bird.svg"
                />
                <h3 class="ml-5">Openfeeder</h3>
              </div>

              <div
                class="
                  bg-blue-400 bg-opacity-30
                  border
                  blue_
                  rounded
                  pb-1
                  pl-1
                  pt-0.5
                  mt-4
                  shadow-lg
                  break-all
                "
              >
                <p>
                  <span
                    class="
                      blue
                      rounded-sm
                      text-white
                      pl-4
                      pr-4
                      pt-0.5
                      pb-0.5
                      text-sm
                    "
                    >GET</span
                  >
                  <span class="text-xs">
                    <strong> /openfeeder/measurements</strong> List ans map all
                    available measurements with name and ID</span
                  >
                </p>
              </div>

              <div
                class="
                  bg-blue-400 bg-opacity-30
                  border
                  blue_
                  rounded
                  pb-1
                  pl-1
                  pt-0.5
                  mt-4
                  shadow-lg
                  break-all
                "
              >
                <p>
                  <span
                    class="
                      blue
                      rounded-sm
                      text-white
                      pl-4
                      pr-4
                      pt-0.5
                      pb-0.5
                      text-sm
                    "
                    >GET</span
                  >
                  <span class="text-xs">
                    <strong>
                      /openfeeder/site/{site_id}/parameter/{parameter_id}</strong
                    >
                    Get data from Smartnest</span
                  >
                </p>
              </div>
            </div>

                        <div>
              <div class="flex items-center mt-10">
                <img
                  class="object-contain h-30 w-10"
                  src="@/assets/img/bird-house.svg"
                />
                <h3 class="ml-5">Smartnest</h3>
              </div>

              <div
                class="
                  bg-blue-400 bg-opacity-30
                  border
                  blue_
                  rounded
                  pb-1
                  pl-1
                  pt-0.5
                  mt-4
                  shadow-lg
                  break-all
                "
              >
                <p>
                  <span
                    class="
                      blue
                      rounded-sm
                      text-white
                      pl-4
                      pr-4
                      pt-0.5
                      pb-0.5
                      text-sm
                    "
                    >GET</span
                  >
                  <span class="text-xs">
                    <strong> /smartnest/measurements</strong> List ans map all
                    available measurements with name and ID</span
                  >
                </p>
              </div>

              <div
                class="
                  bg-blue-400 bg-opacity-30
                  border
                  blue_
                  rounded
                  pb-1
                  pl-1
                  pt-0.5
                  mt-4
                  shadow-lg
                  break-all
                "
              >
                <p>
                  <span
                    class="
                      blue
                      rounded-sm
                      text-white
                      pl-4
                      pr-4
                      pt-0.5
                      pb-0.5
                      text-sm
                    "
                    >GET</span
                  >
                  <span class="text-xs">
                    <strong>
                      /smartnest/site/{site_id}/parameter/{parameter_id}</strong
                    >
                    Get data from Smartnest</span
                  >
                </p>
              </div>
            </div>




          </div>
        </div>
      </div>
    </div>
  </div>
</template>





<style>
.blue {
  background-color: #61affe;
}

.blue_ {
  border-color: #61affe;
}

.vert {
  background-color: #49cc90;
  opacity: 0.5;
}

.green {
  background-color: #49cc90;
}

.green_ {
  border-color: #49cc90;
}
</style>
